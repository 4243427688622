.sidebar {
  ul {
    list-style: none !important;
    margin-left: 0 !important;
  }
}

.sidebar-link:hover .icon {
  color: #114B8F !important;
}

.sidebar-link:hover .title {
  color: #2D3748 !important;
  font-weight: 700 !important;
}

.menu:not(:last-child) {
  padding-bottom: 0.625rem;
  margin-bottom: 1.25rem;
  border-bottom: 2px solid #f6f6f6;
}

.menu:last-child {
  margin-top: auto;
}

.sub-menu {
  display: none;
  animation: vanish 0.3s;
}

.sub-menu-active {
  display: inline-block;
  animation: appear 0.3s;
}

.sidebar-collapsed .menu > ul > li > a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-collapsed .menu > ul > li > a .text {
  position: absolute;
  left: 100px;
  top: 50%;
  transform: translateY(-50%);
  padding: 0.625rem;
  border-radius: 0.25rem;
  background-color: var(--color-secondary);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  color: var(--color-quinary) !important;
  z-index: 1;
  width: max-content !important;
}

.sidebar-collapsed .menu > ul > li > a .text::after {
  content: '';
  position: absolute;
  left: -5px;
  top: 28%;
  width: 20px;
  height: 20px;
  border-radius: 0.125rem;
  background-color: var(--color-secondary);
  transform: rotate(45deg);
  z-index: -1;
}

.sidebar-collapsed .menu > ul > li > a:hover .text {
  left: 70px;
  opacity: 1;
  visibility: visible;
}

.sidebar-collapsed .menu .sub-menu {
  position: absolute;
  top: 0;
  left: 84px; /* Aumentar aqui de acordo os níveis ? */
  width: 200px;
  border-radius: 1.25rem;
  padding: 0.625rem 1.25rem;
  border: 1px solid #f6f6f6;
  background-color: #ffffff;
  box-shadow: 0px 10px 8px rgba(0, 0, 0, 0.1);
}

.menu ul li {
  position: relative !important;
}

.sidebar-collapsed .menu .sub-menu li {
  border: none;
  text-align: start;
  padding: 0 0.5rem;
  margin: 0.5rem 0;
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes vanish {
  from {
    display: inline-block;
    opacity: 1;
  }

  to {
    display: none;
    opacity: 0;
  }
}